import React from 'react';
import {
    Box,
    Heading
} from '@chakra-ui/react'

import WinterSceneNoMotion from './WinterSceneNoMotion';

export default function SpringBanner(props) {
    return (
        <Box position="relative" bg="#05364f">
          <Heading as="h1" variant="h1" textAlign="center" color="white" pt="12" mb="0" px="8">
               {props.headline}
          </Heading>
           <Box w="100%">
               <WinterSceneNoMotion />
           </Box>
        </Box>
    );
}